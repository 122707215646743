.contact-section {
    padding: 80px 0;
    background-color: #f7f7f7;
    font-family: 'Montserrat', sans-serif;
  }

  .contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .contact-title {
    font-size: 36px;
    font-weight: 700;
    color: #292d34;
    margin-bottom: 20px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 18px;
    color: #6b6f76;
    text-align: center;
    margin-bottom: 40px;
  }

  .contact-form {
    background-color: #ffffff;
    padding: 40px !important;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #292d34;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
  }

  .form-group textarea {
    height: 150px;
  }

  .submit-btn {
    background-color: #7b68ee;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .submit-btn:hover {
    background-color: #6a5acd;
  }