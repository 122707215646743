.contact-form {
    background-color: #ffffff;
    padding: 20px !important;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.features {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .feature {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 1.5rem;
    flex: 1;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .feature:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .feature i {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }

.feature i {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  .feature h3 {
    font-size: 18px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
  .features {
    flex-direction: column;
  }
  /* .feature {
    width: 100%;
    margin-bottom: 1rem;
  } */

}
