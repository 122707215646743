  .featured-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 12px;
    margin: 2rem 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .article-meta {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  .article-meta img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  h1 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
    color: #7B68EE;
  }
  
  .article-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
  }
  
  .social-share {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
  }
  
  .social-share a {
    margin-left: 1rem;
    color: #333;
    transition: color 0.3s ease;
    font-size: 1.2rem;
  }
  
  .social-share a:hover {
    color: #7B68EE;
  }
  
  .related-articles {
    margin: 3rem 0;
    background-color: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .related-articles h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #7B68EE;
  }
  
  .related-articles ul {
    list-style-type: none;
    padding: 0;
  }
  
  .related-articles li {
    margin-bottom: 1rem;
  }
  
  .related-articles a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;
  }
  
  .related-articles a:hover {
    color: #7B68EE;
  }
  
  .comments-section {
    margin: 3rem 0;
    background-color: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .return-to-blog {
    display: inline-block;
    margin-top: 2rem;
    padding: 0.8rem 1.5rem;
    background-color: #7B68EE;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .return-to-blog:hover {
    background-color: #5D4ED1;
  }
  