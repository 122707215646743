.blog-section {
    /* padding: 80px 0; */
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
  }
  
  .blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .blog-title {
    font-size: 36px;
    font-weight: 700;
    color: #292d34;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .blog-subtitle {
    font-size: 18px;
    color: #6b6f76;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .blog-post {
    background-color: #f7f7f7;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-post:hover {
    transform: translateY(-5px);
  }
  
  .blog-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-post-title {
    font-size: 20px;
    font-weight: 600;
    color: #292d34;
    margin: 20px;
  }
  
  .blog-post-excerpt {
    font-size: 14px;
    color: #6b6f76;
    margin: 0 20px 20px;
  }
  
  .blog-post-link {
    display: inline-block;
    margin: 0 20px 20px;
    padding: 10px 20px;
    background-color: #7b68ee;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .blog-post-link:hover {
    background-color: #6a5acd;
  }
  
  .text-center {
    text-align: center;
}
.mb-16 {
    margin-bottom: 4rem;
}
.overflow-x-hidden {
    overflow-x: hidden;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
/* @media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
} */
/* @media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
} */
/* .container {
    width: 100%;
} */

.text-center {
    text-align: center;
}

.mb-16 {
    margin-bottom: 4rem;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.gap-4 {
    gap: 1rem;
}

.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex {
    display: flex;
}
.mb-8 {
    margin-bottom: 2rem;
}

.max-w-2xl {
    max-width: 42rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.gap-2 {
    gap: .5rem;
}

.justify-center {
    justify-content: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex {
    display: flex;
}
.mb-8 {
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
.gap-8 {
    gap: 2rem;
}
.grid-cols-1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid {
    display: grid;
}
.mb-16 {
    margin-bottom: 4rem;
}

.relative {
    position: relative;
}

.search-input {
    background-color: #f7fafc;
    padding: 10px 24px;
    width: 300px;
}


.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border {
    border-width: 1px;
}
.\!rounded-full {
    border-radius: 9999px !important;
}

.gap-8 {
    gap: 2rem;
}
/* Default for mobile: 1 column */
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* 1 column for small screens */
}

/* For screens larger than 768px (tablets and larger): 2 columns */
@media (min-width: 768px) {
    .grid-cols-1 {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 columns for larger screens */
    }
}
.grid {
    display: grid;
}
.mb-16 {
    margin-bottom: 4rem;
}

.animate-fadeInUp {
    -webkit-animation: fadeInUp .6s ease-out;
    animation: fadeInUp .6s ease-out;
}
.hover-lift {
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
}
.blog-card {
    background: #fff;
    border-radius: 1rem;
    overflow: hidden;
    transition: all .3s ease;
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}
.shadow-2xl, .shadow-lg {
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.object-cover {
    object-fit: cover;
}

.h-48 {
    height: 12rem;
}
img, video {
    /* height: auto; */
    max-width: 100%;
}
audio, canvas, embed, iframe, img, object, svg, video {
    display: block;
    vertical-align: middle;
}

.category-badge {
    background: linear-gradient(135deg, #6366f1, #ec4899);
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    transition: all .3s ease;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.font-semibold {
    font-weight: 600;
}
.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}
.py-1 {
    padding-bottom: .25rem;
    padding-top: .25rem;
}
.px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
}
.\!rounded-full {
    border-radius: 9999px !important;
}
.inline-block {
    display: inline-block;
}
.mb-2 {
    margin-bottom: .5rem;
}

.font-semibold {
    font-weight: 600;
}

.p-6 {
    padding: 1.5rem;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex {
    display: flex;
}
.mb-4 {
    margin-bottom: 1rem;
}

.keyword {
    background-color: #e5e7eb;
    border-radius: 4px;
    color: #4b5563;
    cursor: pointer;
    display: inline-block;
    font-size: .875rem;
    margin: 2px;
    padding: 2px 6px;
    transition: background-color .3s ease;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}
.flex {
    display: flex;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

.w-full {
    width: 100%;
}