@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

:root {
  --primary-color: #7B68EE;
  --secondary-color: #4AB9FD;
  --background-color: #F7F7FF;
  --text-color: #292D34;
  --success-color: #24B47E;
  --error-color: #F25C54;
}

/* General Styling for Body and Container */
body, html {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: var(--text-color);
  background-color: var(--background-color);
  
  /* Flexbox centering for both mobile and desktop */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container: Desktop and Mobile */
.container {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  margin-top: 7rem !important;

  /* Responsive max width and width for desktop */
  max-width: 1200px;
  width: 84%; 
  margin: 0 auto; /* Centers the container horizontally */
  
  animation: fadeIn 0.5s ease-out;
}

/* Optional: Add a fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Default for mobile: 1 column */
h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 1.9rem !important;
  font-weight: 700;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* For screens larger than 768px (tablets and larger): 2 columns */
@media (min-width: 768px) {
  h1 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    font-size: 2.5rem !important;
    font-weight: 700;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.logoname {
  color: var(--primary-color);
  font-weight: 700;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  text-align: center;
  color: var(--text-color);
  opacity: 0.8;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.input-group {
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
}

input {
  flex-grow: 1;
  padding: 0.75rem 1rem 0.75rem 3rem;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 3px rgba(74, 185, 253, 0.3);
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

button {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  margin-left: 0.5rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: var(--secondary-color);
}

.controls {
  display: none;
}

.controls.show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.toggle-container, .sort-container {
  display: flex;
  align-items: center;
}

.toggle-label, .sort-label {
  margin-right: 0.5rem;
  font-weight: 600;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.filter-buttons {
  display: flex;
  gap: 0.5rem;
}

.filter-button {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.filter-button.active {
  background: var(--primary-color);
  color: white;
}

.results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.result-item {
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.result-item.show {
  opacity: 1;
  transform: translateY(0);
}

.result-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.result-item a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: all 0.3s ease;
  padding-top: 9px;
}

.icon-success{
  color: green;
    padding-right: 6px !important;
}

.icon-error{
  color: red;
    padding-right: 6px !important;
}

.result-item a:hover {
  color: var(--secondary-color);
}

.status {
  font-weight: bold;
  margin-top: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status i {
  margin-right: 0.5rem;
}

.available i {
  color: var(--success-color);
}

.taken i {
  color: var(--error-color);
}

.result-item.available {
  background-color: rgba(36, 180, 126, 0.1);
  border: 1px solid var(--success-color);
}

.result-item.taken {
  background-color: rgba(242, 92, 84, 0.1);
  border: 1px solid var(--error-color);
}

.platform-icon-available {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: green;
}

.platform-icon-notavailable {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: red;
}


@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.checking {
  animation: pulse 1s infinite;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading::after {
  content: "";
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.compact-mode .result-item {
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.compact-mode .platform-icon {
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.compact-mode .status {
  margin-top: 0;
}

.compact-mode a {
  display: none;
}

select {
  padding: 0.5rem;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}

.copy-btn {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.trending-usernames {
  margin-top: 2rem;
}

.trending-usernames h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.trending-usernames ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.trending-usernames li {
  background-color: var(--secondary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.trending-usernames li:hover {
  background-color: var(--primary-color);
  transform: translateY(-2px);
}

#bulkUsernames {
  border: 2px solid var(--primary-color);
  border-radius: 12px;
  padding: 0.5rem;
  font-family: inherit;
  resize: vertical;
}

@media (max-width: 768px) {
  .results {
    grid-template-columns: repeat(2, 1fr);
  }
  .controls.show {
    flex-direction: column;
    align-items: stretch;
  }
  .filter-buttons, .toggle-container, .sort-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .results {
    grid-template-columns: 1fr;
  }
  .input-group {
    flex-direction: column;
  }
  input, button {
    /* width: 100%; */
    margin-left: 0;
  }
  button {
    margin-top: 1rem;
  }
}

/* body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%239C92AC' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E");
  pointer-events: none;
  z-index: -1;
} */

.navbar {
  background-color: white;
  /* padding: 1rem 0; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 0.3rem;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-logo img {
  margin-right: 0.5rem;
  margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 3.25rem;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin-left: 1.5rem;
}

.navbar-menu a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  font-weight: 600;
}

.navbar-menu a:hover,
.navbar-menu a.active {
  color: #f84703;
}

/* Mobile Menu Toggle */
.menu-toggle {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: var(--primary-color);
}

/* Show Menu on Mobile */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .navbar-menu {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: var(--primary-color);
    width: 100%;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .navbar-menu a:hover,
.navbar-menu a.active {
  color: var(--background-color);
}
.navbar-menu a {
  color: var(--background-color);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  font-weight: 600;
}

  .navbar-menu.active {
    left: 0;
  }

  .navbar-menu li {
    margin: 2.5rem 0;
  }

  .navbar-menu a {
    font-size: 1.2rem;
  }

  .container {
    max-width: 100%; /* Ensures it takes full width on small devices */
    padding: 1.5rem; /* Slightly reduce padding on mobile */
    margin-top: 8rem !important;
  }

  .navbar-logo img {
      margin-left: 0.07rem;
  }
}

/* --- new */

/* Loader Styles */
.creative-loader {
  display: flex;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.creative-loader .loader-content {
  text-align: center;
}

.creative-loader .loader-text {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
}

.creative-loader .letter {
  display: inline-block;
  opacity: 0;
  color: inherit;
  transform: translateY(1em);
}

.creative-loader .drop-in {
  animation: dropIn 0.6s ease forwards;
}

@keyframes dropIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.creative-loader .loader-icon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.circular-loader {
  animation: rotate 2s linear infinite;
}

.loader-path {
  stroke: var(--primary-color);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


/* Footer Styles: Desktop and Mobile */
.footer {
  background-color: #7B68EE;
  color: white;
  padding: 3rem 0 1rem;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.footer-section {
  flex: 1;
  margin-bottom: 2rem;
  min-width: 200px;
}

.footer-section h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #f84706;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #f84706;
}

.newsletter-form {
  display: flex;
  margin-top: 1rem;
}

.newsletter-form input {
  flex-grow: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 4px 0 0 4px;
}

.newsletter-form button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0 4px 4px 0;
  background-color: #f84703;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #3a9edb;
}

.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Footer for Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center; /* Aligns content for mobile */
  }

  .footer-section {
    margin-bottom: 1.5rem;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input {
    margin-bottom: 1rem;
    border-radius: 4px; /* Full rounded input for mobile */
  }

  .newsletter-form button {
    border-radius: 4px; /* Full rounded button for mobile */
  }

  .social-icons {
    justify-content: center;
  }
}

/* Optional: Fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.faq-container {
  /* background: #f7f7f7; */
  /* border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  margin-top: 7rem !important;  */

  background: linear-gradient(to right, #7b68eec7, #4ab9fd70);
  border-radius: 12px;
  max-width: 1200px;
  width: 85%;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-out;
  padding: 1.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  backdrop-filter: blur(10px);
}

.faq-section {
  margin-top: 60px;
}

.faq-item {
  cursor: pointer;
  background: #fefefee3;
  padding: 20px;
  margin-bottom: 10px;
}
